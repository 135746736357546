.container {
    margin: 0 auto;
    max-width: var(--login-card-width);
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    border-radius: var(--normal-border-radius);
    border: 1px solid var(--border-gray);
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: var(--double-padding);
    gap: var(--single-padding);
}