.container {
    display: flex;
    padding: var(--double-padding);
    justify-content: center;
    flex-direction: column;
    width: 410px;
    margin: auto;
    background-color: var(--white);
    border: 1px solid var(--border-gray);
    border-radius: var(--normal-border-radius);
    text-align: center;
    gap: var(--single-padding);
    align-items: stretch;
}