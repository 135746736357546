.container {
    display: flex;
    gap: var(--single-padding);
    min-width: fit-content;
}

@media only screen and (max-width: 1023px) {
    .container {
        gap: var(--single-padding);
    }
}


.card-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: var(--single-padding);
    width: var(--login-card-width);
    padding: var(--single-padding);
    background-color: var(--white);
    border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius)
    var(--normal-border-radius);
}
