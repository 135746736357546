.container {
    min-width: fit-content;
}

.passenger-info {
    align-self: flex-start;
}

.align-right Button {
    margin-left: auto;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
}

.alt-sail-dates-container {
    margin-bottom: var(--double-padding);
}