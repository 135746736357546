/* Centre modal in view port */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    padding: var(--double-padding);
    border-radius: var(--large-border-radius);
    z-index: 100;
}

.modal:focus {
    outline: 2px solid var(--tertiary-sky-blue);
}

/* Set an opaque cover over page content behind the modal, keeps mouse clicks on modal where ever they are in the page */
.modal:before {
    content: '';
    position: fixed;
    top: -10000px;
    left: -5000px;
    width: 10000px;
    height: 90000px;
    background: #33333340;
    z-index: -2;
}

/* To give modal white background, needs to be applied to :after which is above the :before. (background style on .modal is below the :before) */
.modal:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white); /* if this ever changes, update transition below */
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    z-index: -1;
}

/* Modal is only visible when open; default display = none. */
.isOpen {
    display: block;
}

/* add invisible and v. quick transition so we can listen to focus leaving modal */
.modal:not(:focus-within) {
    transition: background-color 0.01s;
    background-color: rgb(
        255,
        255,
        254
    ); /* NOTE: if back colour is changed from white, this needs updating */
}

.header {
    margin-right: 3rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.content {
    max-height: 90vh;
    min-height: 320px;
    overflow: auto;
    /* tried adding width control but seems to only work when the content passed in sets min/max widths */
}

/* close button is the last element in the dialog (for cycling tab back to modal), but needs visually placing in the top right for display */
.closeButton {
    position: fixed;
    top: 1rem;
    right: 1.5rem;
}
