.table {
    border-spacing: 0;
    thead {
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
        position: relative;
        tr {
            margin: 0 var(--half-padding) 0 var(--one-and-half-padding);
            margin-right: 30px; // to offset for scrollbar, otherwise tbody columns wouldnt match thead columns
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    th, td {
        padding: var(--single-padding) 0;
    }
    td {
        display: flex;
        align-items: center;
        padding-right: var(--single-padding);
    }
    &-body-row {
        border-bottom: 1px solid var(--border-gray);
        padding: 0 var(--half-padding) 0 var(--one-and-half-padding);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        td {
            padding: var(--single-padding) 0;
        }
        &:hover {
            background-color: var(--highlight-blue);
        }
    }
}

.rounded-top-border-radius {
    border-radius:  var(--large-border-radius) var(--large-border-radius) 0 0;
    display: block;
}

.arrive-depart-times {
    display: flex;
    vertical-align: top;
    color: var(--disabled-text);
}

.copy-button-container {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-16px, 50%);
}
