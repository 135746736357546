.DatePickerHeader {
    position: relative;

    &__navigation {
        background-color: transparent;
        border-color: transparent;
        padding: var(--triple-padding) var(--half-padding);
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 106px;

        &:hover {
            & svg {
                fill: var(--tertiary-sky-blue);
            }
        }

        &:focus {
            border: var(--border-L) solid transparent;
            outline: var(--border-S) solid transparent;
            & svg {
                fill: var(--tertiary-sky-blue);
            }
        }

        &:active {
            background-color: transparent;
            border-color: transparent;
            outline: none;
        }

        &--previous {
            left: 0.75rem;
        }

        &--next {
            right: 0.75rem;
        }

        svg {
            height: 1rem;
        }
    }

    &__navigation-selects {
        display: flex;
        justify-content: center;
        margin-bottom: var(--half-padding);

        & select {
            background-color: var(--white);
            color: var(--primary-midnight);
            outline: none;
            border-color: transparent;
            font-size: var(--font-size-S);
            width: auto;
            text-align: center;
        }
    }
}
