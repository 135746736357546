.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;

    & > button {
        & svg {
            fill: var(--secondary-hot-pink);
        }

        &:hover {
            & svg {
                fill: var(--secondary-hot-pink) !important;
            }
        }

        &:focus {
            outline: var(--border-L) solid var(--primary-midnight);
            & svg {
                fill: var(--secondary-hot-pink) !important;
            }
        }

        /* :active must be after :focus, these are order dependant */
        &:active {
            outline: none;
        }

        &:disabled {
            color: var(--disabled-text);
            cursor: not-allowed;
            & svg {
                fill: var(--disabled-text);
            }
        }
    }
}

.page-button-text {
    align-self: center;
}
