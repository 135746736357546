.container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--half-padding);
    padding: var(--single-padding);
}

.button {
    align-self: flex-start;
    margin: var(--single-padding);
}

.columns {
    display: flex;
    flex-basis: calc(50% - var(--single-padding)/2);
}
