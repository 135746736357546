.label {
    padding-right: var(--half-padding);
    &:disabled {
        color: var(--disabled-text);
    }
    &--onDarkBackground {
        color: var(--white);
    }
}

/* The toggle bit*/
.slider-container {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.25rem;
    cursor: pointer;

    /* Hide default HTML checkbox */
    & input {
        opacity: 0;
        width: 0;
        height: 0;

        /* checked style */
        &:checked + .slider {
            background-color: var(--tertiary-sky-blue);
            border: 1px solid var(--tertiary-sky-blue);

            &:before {
                background-color: var(--white);
                transform: translateX(1.15rem);
            }
        }
    }

    /* unchecked style */
    & .slider {
        position: absolute;
        border: 1px solid var(--primary-midnight);
        border-radius: 1rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--white);
        transition: 0.5s;

        &:before {
            border-radius: 50%;
            position: absolute;
            content: '';
            height: 0.75rem;
            width: 0.75rem;
            left: 0.25rem;
            top: 0.20rem;
            background-color: var(--primary-midnight);
            transition: 0.5s;
        }
    }

    /* disabled state */
    &:disabled {
        cursor: not-allowed;
        & .slider {
            border: 1px solid var(--disabled-text);

            &:before {
                background-color: var(--disabled-text);
            }
        }

        & input:checked + .slider {
            background-color: var(--disabled-text);
            border: 1px solid var(--disabled-text);
        }
    }
}
