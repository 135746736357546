.container {
    display: flex;
    flex-direction: column;
    background: var(--primary-midnight);
}

.modify-passengers {
    display: flex;
    gap: var(--quarter-padding);
    justify-content: space-between;
    align-items: flex-end;

    &-item {
        display: flex;
        gap: var(--single-padding);
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
    margin: var(--single-padding);
}

.controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--double-padding);
    background-color: var(--white);
    padding: var(--double-padding);
    border: 1px solid var(--border-gray);
    border-radius: var(--normal-border-radius);
}

.controls {
    display: flex;
    gap: var(--quarter-padding);
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: calc(50% - var(--double-padding) / 2);
    min-width: calc(240px - var(--double-padding));
}

.passengers-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.passenger {
    display: flex;
    flex-basis: calc(50% - var(--single-padding) / 2);
}

.buttons {
    display: flex;
    gap: var(--single-padding);
    justify-content: flex-end;
}

.additional-information-section {
    &__container {
        border-radius: var(--large-border-radius) var(--large-border-radius)
            var(--normal-border-radius) var(--normal-border-radius);
        background-color: var(--white);
        border-bottom: 3px solid var(--primary-midnight);
        display: flex;
        flex-direction: column;
        min-width: 15rem;
        gap: var(--single-padding);
    }

    &__header {
        border-radius: var(--large-border-radius) var(--large-border-radius)
            var(--normal-border-radius) var(--normal-border-radius);
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
    }
    &__content {
        display: flex;
        flex-direction: column;
        padding-left: var(--single-padding);
        padding-bottom: var(--single-padding);
        gap: var(--single-padding);

        & button {
            display: block;
            width: 100%;
            padding: 0;
            background-color: transparent;
            border: none;
            font: inherit;
            text-align: inherit;
        }
    }
}
