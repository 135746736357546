.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    padding: var(--single-padding);
    background-color: var(--primary-midnight);
    border-radius: var(--large-border-radius);
}

.header {
    gap: var(--quarter-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
}

.search-bar-and-title {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.previous-search-text {
    display: flex;
    align-items: center;
    gap: var(--half-padding);
}

.icon-text {
    display: flex;
    gap: var(--half-padding);
}
