.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    border-radius: 0 0 var(--normal-border-radius) var(--normal-border-radius);
}

.listItem {
    display: flex;
    gap: var(--half-padding);
    & svg {
        width: 1.5rem;
    }
}
