.order-item {
    &__header {
        display: flex;
        flex-direction: column;
        border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
        background-color: var(--primary-midnight);
        padding: var(--single-padding);

        &-title {
            display: flex;
            white-space: nowrap;
            gap: var(--single-padding);
        }
        &-sub-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--single-padding);
        }
        &-sub-text--supplier-ref {
            display: flex;
            align-items: center;
            gap: var(--half-padding);
        }
    }

    &__details {
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        padding: var(--single-padding);
        gap: var(--half-padding);
        &-subheader {
            padding-bottom: var(--half-padding);
        }
        &-itinerary {
            padding: var(--half-padding);
        }
        &-itinerary-divider-icon {
            display: inline;
            margin: 0 var(--quarter-padding);
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;
        border-radius: 0 0 var(--large-border-radius) var(--large-border-radius);
        background-color: var(--primary-midnight);
        gap: var(--single-padding);
        padding: var(--single-padding);
    }
}
