:root {
    /* override ReactTooltip style */
    --rt-opacity: 1 !important;
}

.custom-tooltip {
    padding: var(--half-padding);
    border-radius: var(--quarter-padding);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.025);
    background: var(--white);
    color: var(--primary-midnight);
    z-index: 9999;
}

.custom-tooltip-target {
    cursor: help;
}
