.container {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    gap: var(--double-padding);
}

.heading {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--single-padding);
}

.content {
    display: flex;
    gap: var(--double-padding);
}

.advisory-messages-header {
    display: flex;
    gap: var(--single-padding);
    align-items: center;
}

.advisory-messages-container {
    display: flex;
    flex-direction: column;
    padding: var(--single-padding) var(--triple-padding);
}

.advisory-messages-message {
    display: list-item;
    padding-left: 0;
}

.content-left {
    display: flex;
    flex-direction: column;
    width: 700px;
    gap: var(--double-padding);
}

.content-right {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 350px;
    max-width: 436px;
}

.bottom {
    margin-top: var(--single-padding);
    margin-left: auto;
}
