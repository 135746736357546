.input {
    font-family: var(--standard-fonts);
    font-size: var(--font-size-S);
    color: var(--primary-midnight);
    border: 1px solid var(--dark-gray);
    border-radius: var(--normal-border-radius);
    padding: var(--font-size-XS) var(--double-padding) var(--font-size-XS) var(--single-padding);
    width: 100%;
    /* custom dropdown arrow */
    appearance: none;
    background: url('./../../Icon/svgs/chevron-down.svg') calc(100% - 12px) / 14px no-repeat
        var(--white);
}

.input option:disabled {
    color: var(--disabled-text);
}

.input--errored {
    border-color: var(--error-red);
}

.input--disabled {
    background-color: var(--disabled-background);
    color: var(--disabled-text);
    border-color: var(--disabled-border);
    cursor: not-allowed;
}
