.wrapper {
    width: '100%';
    padding: var(--single-padding);
}

.divider {
    margin: var(--single-padding) 0;
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--half-padding);
    padding: var(--half-padding) var(--single-padding);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.row-small {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--half-padding);
}
