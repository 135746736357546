.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.background {
    display: flex;
    flex: 1;
    flex-grow: 6;
    background-size: cover;
    background-image: url('/src/images/alonso-reyes-YYSKPYHOq4E-unsplash.jpg');
    background-repeat: no-repeat;
    background-position: center 30%;
    position: relative;
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    filter: opacity(25%);
}

.background-overlay {
    position: relative;
    margin: 0 auto;
}