.list {
    display: grid;
    &-item {
        display: grid;
        grid-column: 1/2;
        grid-template-columns: 2fr 3fr;
        align-items: center;
        gap: var(--single-padding);
        padding: var(--single-padding) 0;
        border-bottom: 2px solid var(--border-gray);

        &:first-child {
            padding: 0 0 var(--single-padding) 0;
        }

        &--left {
            display: flex;
            flex-direction: column;
            flex: 1;
            word-break: break-word;
            gap: var(--half-padding);
        }

        &--right {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            gap: var(--half-padding);
        }

        &--group {
            word-break: break-word;
        }
    }
}
