.container {
    background-color: var(--white);
    border-radius: var(--normal-border-radius);
}

.coloured-top {
    border-radius: var(--normal-border-radius) var(--normal-border-radius) 0 0;
    background: linear-gradient(to left, var(--accent-orange-sun), var(--secondary-hot-pink));
    height: var(--single-padding);
}

.content {
    padding: var(--double-padding);
    border-radius: 0 0 var(--normal-border-radius) var(--normal-border-radius);
}
