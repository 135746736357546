/* FONTS */
@import './fonts/fonts.css';

:root {
  /** COLOURS **/
  --primary-midnight: #000D38;
  --primary-midnight-25: #40496A;
  --primary-midnight-40: #666E88;
  --primary-midnight-75: #BFC3CD;
  --highlight-blue: #F2F8FF;

  --secondary-hot-pink: #FF338A;
  --secondary-hot-pink-button-interact: #FF5CA1;
  --secondary-hot-pink-alt: #DB005F;
  /* why is this not on designs? */
  --secondary-hot-pink-25: #FF66A7;

  --tertiary-sky-blue: #0370FC;
  --tertiary-sky-blue-25: #4294FD;

  --accent-orange-sun: #FF940A;
  --accent-orange-sun-25: #FFAF47;

  --white: #FFFFFF;
  --white-25: #FFFFFF40;
  --white-75: #FFFFFFBF;
  --off-white: #F3F3F3;
  --divider-gray: #B3B7C4;

  --black-15: #00000026;
  --black-25: #00000040;
  --black-50: #00000080;


  /* Border (outline) */
  --border-gray: #EDEBE9;
  --border-blue: #7AB4FF;
  --border-pink: #FFACCF;

  --blue-tint: #BCBEC9;
  --light-gray: #C2C2C2;
  --gray: #757575;
  --dark-gray: #333333;

  --disabled-text: #676E83;
  --disabled-border: var(--disabled-text);
  --disabled-background: #F8F8F8;

  --success-green: #406F34;
  --general-background-success-green: #D2E8CC;
  --info-blue: #0256C5;
  --general-background-info-blue: #DDEBFD;
  --warning-orange: #A85F00;
  --general-background-warning: #FEF8E6;
  --error-red: #AB1C1C;
  --general-background-error-red: #FDE9E7;

  /* Text sizes and font */
  --font-size-XS: 0.75rem;
  /* 12px Small text */
  --font-size-S: 1rem;
  /* 16px Base level font - same as browsers use */
  --font-size-M: 1.313rem;
  /* 21px H3 heading level */
  --font-size-L: 1.625rem;
  /* 26px H2 Sub-Heading */
  --font-size-XL: 2.25rem;
  /* 36px H1 Page heading */
  --font-size-XXL: 2.875rem;
  /* 46px Hero/Splash Sub-Heading */
  --font-size-XXXL: 3.875rem;
  /* 62px Hero/Splash Heading */

  --heading-fonts: HouschkaRounded, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  /* backup list of fonts for all browser/devices */
  --standard-fonts: 'QuickSand', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  --mono-fonts: Courier, 'Courier New', monospace;

  /* Layouts */
  --quarter-padding: 4px;
  --half-padding: 8px;
  --single-padding: 16px;
  --one-and-half-padding: 24px;
  --double-padding: 32px;
  --triple-padding: 48px;
  --quadruple-padding: 64px;

  --normal-border-radius: 4px;
  --large-border-radius: 12px;

  --page-width-desktop: 1240px;
  --page-width-mobile: 650px;

  --medium-panel-width: 400px;

  --login-card-width: 450px;
  --login-input-width: 386px;

  --box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
}

/* CSS RESET/DEFAULTS */
html {
  box-sizing: border-box;
  /* This sets the size of an element includes border width, padding etc. the content size inside adjusts. This give consistent/expected layout results. */
  background-color: var(--off-white);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  /* This sets the size of an element includes border width, padding etc. the content size inside adjusts. This give consistent/expected layout results. */
}

body {
  line-height: 1.5;
  /* space paragraphs out */
  font-family: var(--standard-fonts);
  color: var(--primary-midnight);
  margin: 0;
  /* css reset to avoid mostly unwanted defaults*/
  padding: 0;
  /* css reset to avoid mostly unwanted defaults*/
}

p,
ol,
ul,
dl {
  margin: 0;
  /* css reset to avoid mostly unwanted defaults*/
  padding: 0;
  /* css reset to avoid mostly unwanted defaults*/
  line-height: 1.5;
  /* space paragraphs out */
  -webkit-font-smoothing: antialiased;
  /* firefox setting */
  -moz-osx-font-smoothing: grayscale;
  /* MacOs setting */
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
text {
  line-height: 1.5;
  /* space paragraphs out */
  margin: 0;
  /* css reset to avoid mostly unwanted defaults*/
  padding: 0;
  /* css reset to avoid mostly unwanted defaults*/
  max-width: 85ch;
  /* max width for text to avoid long lines of text */
}

h1,
h2,
h3 {
  line-height: 1.4;
  /* space headings out less as they have larger fonts */
}

legend,
fieldset {
  border: transparent;
  margin: 0;
  padding: 0;
}

/* Global Classes */

/* Hide visually only - best practice method to keep for screen readers but hide from visual users. */
.visually-hidden {
  border: 0;
  clip-path: circle(0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

/* Decoration stripe of TT colours: left to right orange, pink, blue, 8px high */
.tt-decoration-stripe {
  height: 10px;
  background: linear-gradient(to right,
      var(--accent-orange-sun) 0%,
      var(--accent-orange-sun) 33%,
      var(--secondary-hot-pink) 33%,
      var(--secondary-hot-pink) 66%,
      var(--tertiary-sky-blue) 66%,
      var(--tertiary-sky-blue) 100%);
}

/* Use on outer div of each page (or next layer in for pages with background image that needs to be full bleed) */
.general-container {
  flex: 1;
  /* Take up all space (so small content doesn't sit centrally but more like a page) */
  max-width: var(--page-width-desktop);
  /* Keep content auto sizing, if view port allows, between sensible smallest and largest */
  min-width: var(--page-width-mobile);
  margin: 0 auto;
  /* To fill left/right with space on big screens */
  padding: var(--double-padding) var(--single-padding);
  /* Design shows large space at top */
}