.spinner {
    display: inline-flex;
    align-items: center;
    gap: var(--half-padding);
}

.circle {
    position: relative;
    width: 1em;
    height: 1em;
}

.path {
    stroke: var(--primary-midnight);
    stroke-width: 6;
    stroke-dasharray: 80;
    stroke-dashoffset: 60;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    transform-origin: center center;
}

.path--alt {
    stroke: var(--off-white);
}

@keyframes dash {
    0% {
        stroke-dashoffset: 80;
        transform: rotate(0deg);
    }
    100% {
        stroke-dashoffset: 80;
        transform: rotate(360deg);
    }
}
