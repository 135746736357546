.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inputs-group--horizontal {
    display: flex;
    gap: var(--single-padding);
}

.label {
    margin-bottom: var(--half-padding);
}

.month-input {
    width: 3.7rem;
}

.day-input {
    width: 3.7rem;
}

.year-input {
    width: 4.4rem;
}
