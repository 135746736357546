.container {
    display: flex;
    border: var(--border-L) solid transparent;
    background-color: var(--tertiary-sky-blue);
    border-radius: var(--large-border-radius);
    padding: var(--half-padding);
    color: var(--white);
    text-align: center;
    width: max-content;

    &:focus {
        border: (--border-L) solid var(--border-blue);
    }
}

.tags-wrapper {
    margin-bottom: var(--quarter-padding);
    display: flex;
    flex-wrap: wrap;
    gap: var(--quarter-padding);
}
