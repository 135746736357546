.search-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: var(--single-padding);
}

.destination {
    min-width: 200px;
    flex-grow: 1;
}

.date-range {
    min-width: 17.5rem;
}

.destination-list-item {
    display: flex;
    justify-content: space-between;
    padding: var(--quarter-padding);
}
