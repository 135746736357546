.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--double-padding);
    gap: 20px;
    background-color: var(--white);
    border: 2px solid var(--border-gray);
    border-radius: var(--normal-border-radius);
    width: var(--login-card-width);
    margin: auto;
}
