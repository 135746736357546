.card {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 18rem;
    background-color: var(--white);
    border-bottom: 3px solid var(--primary-midnight);
    border-radius: var(--normal-border-radius);

    &__without_underline {
        border-bottom: none;
    }

    &__heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
        gap: var(--single-padding);
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--half-padding);
        border-left: 1.5px solid var(--border-gray);
        border-right: 1.5px solid var(--border-gray);
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        border-radius: 0 0 var(--large-border-radius) var(--large-border-radius);
        background-color: var(--primary-midnight);
        padding: var(--single-padding) var(--one-and-half-padding);
        gap: var(--single-padding);
    }
}
