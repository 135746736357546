.container {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border: 2px solid var(--border-gray);
    border-radius: var(--normal-border-radius) var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius);
}

.no-bottom-border-radius {
    border-radius: var(--normal-border-radius) var(--large-border-radius) 0 0;
}

.details {
    display: flex;
    flex-direction: row;
}