.header {
    display: flex;
    align-items: center;
    gap: var(--half-padding);
}

.table {
    border-collapse: collapse;
    border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius)
        var(--normal-border-radius);
    overflow: hidden;
    width: 100%;
}

.table thead {
    background-color: var(--primary-midnight);
}

.table th {
    padding: var(--single-padding);
    text-align: left;
}

.table td {
    padding: var(--single-padding);
    background-color: var(--white);
}

.table tbody tr:last-child {
    border-bottom: 3px solid var(--primary-midnight);
}

.table tbody tr {
    border-left: 1.5px solid var(--border-gray);
    border-right: 1.5px solid var(--border-gray);
}
.table-cell {
    display: flex;
}
