.tabs {
    margin: var(--half-padding) 0;

    & .tab {
        background-color: var(--white);
        border: 1px solid var(--border-gray);
        border-radius: var(--normal-border-radius);
        text-transform: capitalize;
        font-family: var(--standard-fonts);
        font-weight: bold;
        font-size: var(--font-size-S);
        min-width: 70px;
        min-height: 30px;
        padding: var(--single-padding);
        color: var(--primary-midnight);
        margin-right: var(--half-padding);

        &:hover {
            color: var(--tertiary-sky-blue);
            background-color: var(--highlight-blue);
        }

        &.selected {
            background-color: var(--tertiary-sky-blue);
            border: 1px solid var(--tertiary-sky-blue);
            color: var(--white);
        }
    }

    & .indicator {
        display: none;
    }
}
