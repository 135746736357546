.container {
    display: flex;
    flex-direction: column;
    padding: var(--single-padding) var(--one-and-half-padding);
    gap: var(--single-padding);
}

.section {
    display: flex;
    flex-direction: column;
    gap: var(--quarter-padding);
}

.list-item {
    padding-left: 0;
}
