.container {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    gap: var(--double-padding);
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__actions {
        display: flex;
        gap: var(--single-padding);
    }
}
