.order-pricing-summary {
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        width: 100%;
        gap: var(--single-padding);
        flex-wrap: wrap;

        &__cart-ref {
            display: flex;
            gap: var(--half-padding);
        }
    }
}

.pricing-summary-item {
    &__price-item {
        display: flex;
        justify-content: space-between;
    }

    &__price-details {
        display: flex;
        flex-direction: column;
        padding: var(--single-padding);
        gap: var(--single-padding);
    }
}
