.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    padding: var(--single-padding);
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.submit-button {
    margin-left: auto;
}

.inputs-group--vertical {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.inputs-group--horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--single-padding);
}

.middle-name--inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--single-padding);
}

.middle-name--checkbox {
    align-self: flex-end;
    margin-bottom: 12px;
}

.input-half {
    display: flex;
    width: 100%;
    min-width: 320px;
}

.short-input {
    width: 310px;
}

.buttons-wrapper {
    display: flex;
    gap: var(--single-padding);
    align-self: flex-end;
}
