.info-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    flex-grow: 1;
    border: 2px solid;
    padding: calc(var(--single-padding) + var(--quarter-padding));
    border-radius: var(--normal-border-radius);

    &__error {
        background-color: var(--general-background-error-red);
        border-color: var(--error-red);
        & div > span {
            color: var(--error-red);
        }
    }

    &__warning {
        background-color: var(--general-background-warning);
        border-color: var(--accent-orange-sun);
        color: var(--accent-orange-sun);
        & div > span {
            color: var(--warning-orange);
        }
    }

    &__success {
        background-color: var(--general-background-success-green);
        border-color: var(--success-green);
        color: var(--success-green);
        & div > span {
            color: var(--success-green);
        }
    }
    &__info {
        background-color: var(--general-background-info-blue);
        border-color: var(--info-blue);
        color: var(--info-blue);
        & div > span {
            color: var(--info-blue);
        }
    }
}

.buttons {
    display: flex;
    gap: var(--single-padding);
    & button {
        &:last-child:hover {
            border: var(--border-L) solid transparent;
        }
        & svg {
            fill: var(--primary-midnight);
        }
    }
}

.content {
    display: flex;
    gap: var(--single-padding);
    align-items: flex-start;

    & svg {
        margin: var(--quarter-padding) 0;
    }
}

.text-box {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
}

.text {
    display: flex;
    gap: var(--single-padding);
    align-items: flex-start;
}
