.react-datepicker {
    font-family: var(--standard-fonts);
    font-size: var(--font-size-XS);
    background-color: var(--white);
    color: var(--primary-midnight);
    border: 1px solid var(--border-gray);
    border-radius: 2px;
    display: inline-block;
    margin-top: var(--quarter-padding);

    &__day {
        display: inline-block;
        width: 2rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
        background-clip: content-box;

        &:hover, &--selected, &:active {
            background-color: var(--tertiary-sky-blue-25);
            color: var(--white);
            border-radius: 4px;
        }

        &--selected, &:active {
            font-weight: bold;
        }

        &-names {
            white-space: nowrap;
            text-align: center;
        }

        &-name {
            color: var(--gray);
            display: inline-block;
            width: 2rem;
            line-height: 2rem;
            text-align: center;
        }

        &--in-range {
            background-color: var(--highlight-blue);
        }

        &--in-selecting-range {
            background-color: var(--highlight-blue);
        }

        &--selecting-range-start, &--selecting-range-end, &--range-start, &--range-end  {
            background-color: var(--tertiary-sky-blue-25);
            color: var(--white);
            font-weight: bold;
        }

        &--selecting-range-start, &--range-start {
            border-radius: 4px 0 0 4px;
        }

        &--selecting-range-end, &--range-end {
            border-radius: 0 4px 4px 0;
        }

        &--disabled {
            color: var(--light-gray);
            cursor: not-allowed;
            &:active {
                background-color: transparent;
            }
        }
    }

    &__week {
        margin-top: 2px;
    }

    &__month {
        text-align: center;
        &-container {
            padding: var(--single-padding) var(--triple-padding);
        }
    }
    &__aria-live {
        position: absolute;
        clip-path: circle(0);
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
        white-space: nowrap;
    }
}

.react-datepicker-popper {
    z-index: 999;
}
