.colored-line {
    background-color: var(--primary-midnight);
    height: 1px;
    width: 100%;
    &--on-dark-background {
      background-color: var(--divider-gray);
    }
}

.padding--non {
    margin: var(--half-padding) 0;
}

.padding--single {
    margin: var(--half-padding) var(--single-padding);
}

.padding--double {
    margin: var(--half-padding) var(--double-padding);
}
