.controller-container {
    padding: var(--half-padding) 0;
    list-style-type: none;
    display: flex;
    gap: var(--quarter-padding);
    align-items: center;
    color: var(--primary-midnight);
    justify-content: center;

    & > ul {
        flex-wrap: nowrap;
    }
}

.buttons {
  font-weight: bold !important;
  font-family: var(--standard-fonts) !important;
  min-height: 42px;
  min-width: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

    &:hover {
        background-color: var(--highlight-blue);
        cursor: pointer;
    }

    &:active {
        background-color: var(--tertiary-sky-blue-25);
        color: var(--off-white);
    }

    & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
    }
}

.ellipsis {
    &:hover {
        background-color: inherit !important;
        cursor: auto;
    }

    &:active {
        background-color: inherit !important;
        color: inherit !important;
    }
}

.active-item {
  background-color: var(--tertiary-sky-blue) !important;
  color: var(--off-white) !important;

  &:hover {
    background-color: var(--tertiary-sky-blue) !important;
    cursor: not-allowed !important;
  }
}
