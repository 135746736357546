.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--normal-padding);
    background-color: white;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.counter {
    min-width: 25px;
    padding-left: 8px;
}
