.menu {
    &__container {
        display: flex;
        align-items: center;
        gap: var(--half-padding);
    }
    &__button {
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: var(--half-padding);
        padding: 10px var(--single-padding);
        border: solid 1px var(--primary-midnight);
        border-radius: var(--normal-border-radius);

        &:disabled {
            cursor: not-allowed;
        }
        &:focus,
        &:hover,
        &:active {
            background-color: var(--border-gray);
        }
        &--onDarkBackground:focus,
        &--onDarkBackground:hover,
        &--onDarkBackground:active {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}
