.container {
    display: flex;
    flex-grow: 1;
    position: relative;
}

.text-input {
    background-color: var(--white);
    border: 1px solid var(--dark-gray);
    color: var(--dark-gray);
    padding: var(--font-size-XS) var(--single-padding);
    font-family: var(--standard-fonts);
    font-size: var(--font-size-S);
    width: 100%;
    text-align: left;
    border-radius: var(--normal-border-radius);
}

.text-input--errored {
    border-color: var(--error-red);
}

.text-input--disabled {
    background-color: var(--disabled-background);
    color: var(--disabled-text);
    border-color: var(--disabled-border);
    cursor: not-allowed;
}

.text-input--icon-left-padding {
    padding-left: var(--triple-padding);
}

.text-input--icon-right-padding {
    padding-right: var(--double-padding);
}

.input-icon {
    border: 1px solid transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: var(--single-padding);
    top: 0;
    left: 0;
}

.clear-icon {
    border: 1px solid transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    top: 0;
    right: var(--quarter-padding);
}
