.container {
    background-color: var(--white);
    border: 1px solid var(--border-gray);
    border-radius: var(--normal-border-radius);
    display: flex;
    padding: var(--single-padding);
    justify-content: space-between;
    flex-grow: 1;
    min-height: 180px;
    min-width: 340px;

    &--placeholder {
        background-color: transparent;
        border: 10px dashed var(--border-gray);
        border-radius: var(--normal-border-radius);
        justify-content: center;
        align-items: center;

        & svg {
            fill: var(--border-gray);
            height: 5rem;
        }
    }
}

.past-passenger {
    position: relative;
}

.past-passenger-input {
    display: flex;
    gap: var(--single-padding);
    align-items: flex-end;

    & > button {
        margin: 2px 0;
    }
}

.user-input-displayed {
    display: flex;
    align-items: center;
    margin-top: var(--half-padding);
    position: absolute;

    & > span {
        margin-right: var(--single-padding);
    }

    & > button > span > svg {
        fill: var(--primary-midnight);
    }
}

.child-age-input {
    display: flex;
    gap: var(--single-padding);

    & > span {
        align-self: end;
    }
}

.close-button {
    position: relative;
    top: 0;
    right: 0;
}

.heading {
    display: flex;
    margin-bottom: var(--half-padding);
    gap: var(--single-padding);
}