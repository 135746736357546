.header {
    background-color: var(--primary-midnight);
    display: flex;
    min-height: 60px; /* this should be smaller than any content but some value in case its empty */
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    gap: var(--single-padding);
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: var(--page-width-mobile);
    padding: var(--double-padding);
    max-width: var(--page-width-desktop);
    margin: 0 auto;
}
