.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 75%;
    padding: var(--single-padding);
    border-radius: var(--normal-border-radius) 0 0 var(--normal-border-radius);
}

.header {
    display: flex;
    justify-content: space-between;
    gap: var(--single-padding);
}

.icon-list {
    margin: var(--single-padding) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.icon-list__item {
    display: flex;
    gap: var(--half-padding);
}

.image-itinerary {
    display: flex;
    gap: var(--single-padding);
}

.image {
    width: 300px;
    flex: 0 0 auto;
}

.logo {
    width: 90px;
}

.details-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    margin-right: 4px;
    color: var(--tertiary-sky-blue);
}

.itinerary-label {
    margin-right: var(--quarter-padding);
}

.itinerary-divider-icon {
    display: inline;
    margin: 0 var(--quarter-padding);
}

.more-button {
    background-color: var(--white);
    border: none;
    padding: 0;
    color: var(--tertiary-sky-blue);
    text-decoration: underline;
}

.more-button:hover {
    cursor: pointer;
}

.not-integrate-message {
    text-align: center;
    padding: var(--half-padding) var(--single-padding);
    background-color: var(--accent-orange-sun-25);
    margin: 0 -16px;
}
