.container {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
    min-width: fit-content;
    flex-grow: 1;
}

.groups {
    &-header {
        display: flex;
        justify-content: space-between;
        padding: var(--single-padding) var(--double-padding);
        background-color: var(--primary-midnight);
        border-radius: var(--large-border-radius) var(--large-border-radius) 0px 0px;
    }

    &-add {
        display: flex;
        flex-direction: column;
        gap: var(--single-padding);
        padding: var(--single-padding);
        background-color: var(--white);
        border-radius: var(--normal-border-radius);
    }

    &-filter {
        display: flex;
        flex-direction: column;
        gap: var(--single-padding);
        padding: var(--single-padding);
        background-color: var(--white);
        border-radius: var(--normal-border-radius);

        &-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & input {
                width: 350px;
            }
        }
    }

    &-content {
        background-color: var(--white);
        border-radius: 0px 0px var(--normal-border-radius) var(--normal-border-radius);
        padding: var(--single-padding);
        min-height: 350px;
    }
}
