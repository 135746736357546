.order-payment-schedule-section {
    display: flex;
    flex-direction: column;
    min-width: 18rem;
    border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius) var(--normal-border-radius);
    background-color: var(--white);
    border-bottom: 3px solid var(--primary-midnight);
    &__heading {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
        gap: var(--single-padding);

        &__cart-ref {
            display: flex;
            gap: var(--half-padding);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--half-padding);
        border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius) var(--normal-border-radius);

    }
}

.order-payment-schedule-item {
    &__line-margin {
        margin-right: var(--double-padding);
    }
    &__price-item {
        display: flex;
        justify-content: space-between;
    }

    &__price-details {
        display: flex;
        flex-direction: column;
        padding: var(--single-padding);
    }
}

.margin-right-small {
    margin-right: var(--half-padding);
}
