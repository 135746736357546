.spacing {
    display: flex;
}

.spacing-single {
    padding: 8px 0;
}

.spacing-double {
    padding: 16px 0;
}

.spacing-triple {
    padding: 24px 0;
}

.spacing-quadruple {
    padding: 32px 0;
}
