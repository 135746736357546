.close-button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: var(--normal-border-radius);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover:enabled {
        cursor: pointer;
        background-color: var(--black-15);
    }
    &:focus {
        background-color: var(--black-15);
        border: 1px solid var(--black-50);
    }
    &:disabled {
        cursor: not-allowed;
        & svg {
            fill: var(--disabled-text);
        }
    }

    &--light-coloured-background {
        &:focus {
            border: 1px solid var(--black-50);
        }

        &:disabled {
            & svg {
                fill: var(--border-blue);
            }
        }
    }

    &--dark-coloured-background {
        &:hover:enabled {
            background-color: var(--white-25);
        }
        &:focus {
            background-color: var(--white-25);
            border: 1px solid var(--white-75);
        }
    }
}
