/* Border thickness */
button {
    --border-S: 1px;
    --border-L: 2px;
}

.button {
    font-family: var(--standard-fonts);
    font-weight: bold;
    font-size: var(--font-size-S);
    padding: 10px var(--single-padding);
    border-radius: var(--normal-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--half-padding);
    &:hover {
        cursor: pointer;
    }

    &__primary {
        background-color: var(--secondary-hot-pink);
        color: var(--white);
        border: var(--border-L) solid var(--secondary-hot-pink); /* needed to make same height as secondary button, in case they are next to each other */

        &:hover {
            filter: brightness(0.95);
        }

        &:focus {
            background: var(--secondary-hot-pink-button-interact);
            border: var(--border-L) solid var(--primary-midnight); /* needed to make same height as secondary button, in case they are next to each other */
            outline: var(--border-S) solid var(--primary-midnight);
        }

        /* :active must be after :focus, these are order dependant */
        &:active {
            outline: none;
            background: var(--secondary-hot-pink-button-interact);
            border: var(--border-L) solid var(--secondary-hot-pink-button-interact); /* needed to make same hieght as secondary button, in case they are next to each other */
        }

        &:disabled {
            background-color: var(--disabled-background);
            border: var(--border-L) solid var(--disabled-border);
            color: var(--disabled-text);
            cursor: not-allowed;
            filter: none;
            & svg {
                fill: var(--disabled-text);
            }
        }

        &--alt:focus {
            border-color: var(--white);
            outline-color: var(--white);
        }
    }

    &__secondary {
        background-color: var(--tertiary-sky-blue);
        color: var(--white);
        border: var(--border-L) solid var(--tertiary-sky-blue);

        &:hover {
            filter: brightness(0.95);
        }

        &:focus {
            border: var(--border-L) solid var(--primary-midnight);
            outline: var(--border-S) solid var(--primary-midnight);
        }

        /* :active must be after :focus, these are order dependant */
        &:active {
            outline: none;
            border: var(--border-L) solid var(--tertiary-sky-blue-25);
            background-color: var(--tertiary-sky-blue-25);
        }

        &:disabled {
            background-color: var(--disabled-background);
            border: var(--border-L) solid var(--disabled-border);
            color: var(--disabled-text);
            cursor: not-allowed;
            filter: none;
            & svg {
                fill: var(--disabled-text);
            }
        }

        &--alt:focus {
            border-color: var(--white);
            outline-color: var(--white);
        }
    }

    &__tertiary {
        background-color: transparent;
        color: var(--primary-midnight);
        border: var(--border-L) solid var(--primary-midnight);

        &:hover {
            background-color: var(--border-gray);
        }

        &:focus {
            outline: var(--border-L) solid var(--primary-midnight);
            background-color: var(--border-gray);
        }

        /* :active must be after :focus, these are order dependant */
        &:active {
            outline: none;
            background-color: var(--border-gray);
        }

        &:disabled {
            border: 1px solid var(--disabled-text); /* colour used is same as text */
            color: var(--disabled-text);
            cursor: not-allowed;
            filter: none;
            & svg {
                fill: var(--disabled-text);
            }
        }

        &--alt {
            color: var(--white);
            border-color: var(--white);

            &:hover {
                background-color: rgba(255, 255, 255, 0.10);
            }

            &:focus {
                border-color: var(--white);
                outline-color: var(--white);
                background-color: rgba(255, 255, 255, 0.10);
            }

            &:active {
                outline: none;
                background-color: rgba(255, 255, 255, 0.10);
            }
        }
    }

    &__text,
    &__icon {
        color: var(--tertiary-sky-blue);
        background-color: transparent;
        border: var(--border-L) solid transparent;

        &:hover {
            color: var(--tertiary-sky-blue-25);
            background-color: var(--highlight-blue);
            border: var(--border-L) solid var(--highlight-blue);
            & svg {
                fill: var(--tertiary-sky-blue-25);
            }
        }

        &:focus {
            color: var(--tertiary-sky-blue-25);
            background-color: var(--highlight-blue);
            border: var(--border-L) solid var(--tertiary-sky-blue);
            outline: var(--border-S) solid var(--tertiary-sky-blue);
            & svg {
                fill: var(--tertiary-sky-blue-25);
            }
        }

        /* :active must be after :focus, these are order dependant */
        &:active {
            outline: none;
            color: var(--tertiary-sky-blue);
            & svg {
                fill: var(--tertiary-sky-blue);
            }
        }

        &:disabled {
            border: var(--border-L) solid transparent;
            background-color: transparent;
            color: var(--disabled-text);
            cursor: not-allowed;
            & svg {
                fill: var(--disabled-text);
            }
        }

        &--alt {
            color: var(--white);
            background-color: transparent;
            & svg {
                fill: var(--white);
            }

            &:hover {
                background-color: var(--primary-midnight-25);
                border: var(--border-L) solid var(--primary-midnight-25);
                & svg {
                    fill: var(--white);
                }
            }

            &:focus {
                background-color: var(--primary-midnight-25);
                border: var(--border-L) solid var(--white);
                outline: var(--border-S) solid var(--white);
            }

            &:active {
                outline: none;
                color: var(--white);
                & svg {
                    fill: var(--white);
                }
            }

            &:disabled {
                border: var(--border-L) solid transparent;
                background-color: transparent;
                color: var(--disabled-text);
                cursor: not-allowed;
                & svg {
                    fill: var(--disabled-text);
                }
            }
        }
    }

    /* Icon only is same style at Text, except the below*/
    &__icon {
        padding: 10px;
        &:active {
            background-color: transparent;
            border-color: transparent;
            outline: none;
        }
    }
}
