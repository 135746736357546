.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 25rem;
    gap: var(--double-padding);
}

.payment-section {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.payment-options-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.payment-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    flex-grow: 1;
    justify-content: space-between;
    flex-direction: row;
    gap: var(--single-padding);
    padding: var(--single-padding);
    border-radius: var(--normal-border-radius);
    border: 3px solid transparent;
    background: linear-gradient(180deg, var(--white), var(--white)) padding-box,
        linear-gradient(180deg, var(--border-gray), var(--border-gray)) border-box;

    &:hover, &:focus {
        background: linear-gradient(180deg, var(--highlight-blue), var(--highlight-blue)) padding-box,
        linear-gradient(180deg, var(--border-blue), var(--border-blue)) border-box;
    }

    /* :active must be after :focus, these are order dependant */
    &:active {
        background: linear-gradient(180deg, var(--white), var(--white)) padding-box,
            linear-gradient(180deg, var(--accent-orange-sun), var(--secondary-hot-pink)) border-box;
    }

    &--chosen {
        background: linear-gradient(180deg, var(--white), var(--white)) padding-box,
        linear-gradient(180deg, var(--accent-orange-sun), var(--secondary-hot-pink)) border-box;

        &:hover, &:focus { // needed to override :unselected hover/focus styles
            background: linear-gradient(180deg, var(--highlight-blue), var(--highlight-blue)) padding-box,
            linear-gradient(180deg, var(--accent-orange-sun), var(--secondary-hot-pink)) border-box;
        }
        &:active {
            background: linear-gradient(180deg, var(--white), var(--white)) padding-box,
            linear-gradient(180deg, var(--accent-orange-sun), var(--secondary-hot-pink)) border-box;
        }
    }

    &:disabled {
        cursor: not-allowed;
        background: linear-gradient(180deg, var(--disabled-background), var(--disabled-background)) padding-box,
        linear-gradient(180deg, var(--disabled-border), var(--disabled-border)) border-box;
        color: var(--disabled-text);
        &:hover, &:focus {
            background: linear-gradient(180deg, var(--disabled-background), var(--disabled-background)) padding-box,
            linear-gradient(180deg, var(--disabled-border), var(--disabled-border)) border-box;
        }
    }
}
.payment-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.payment-form {
    &__form {
        border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius) var(--normal-border-radius);
        background-color: var(--white);
        border-bottom: 3px solid var(--primary-midnight);
        display: flex;
        flex-direction: column;
        min-width: 15rem;
    }

    &__form-header {
        border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
    }

    &__form-content {
        padding: var(--single-padding);
        display: flex;
        flex-direction: column;
        gap: var(--single-padding);
    }
}

.inputs-group--horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.inputs-group--vertical {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.input-grow-container {
    display: flex;
    flex-grow: 1;
}

.input-small-container {
    width: 100px;
}

.double-gap {
    gap: var(--double-padding);
}

.align-below-label {
    margin-top: 1.5rem;
}

.display-contents {
    display: flex;
    padding-top: var(--single-padding);
    gap: var(--single-padding);
}

.card-name-and-number {
    flex-basis: 250px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.buttons-wrapper {
    display: flex;
    gap: var(--single-padding);
    align-self: flex-end;
}

.red-asterisk {
    font-weight: bold;
    color: red;
}
