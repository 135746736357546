.content {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
    & .text {
        padding: var(--single-padding);
    }
    & .table {
        border-collapse: collapse;
        margin: 0 var(--single-padding);
        & th,
        & td {
            text-align: left;
            padding: var(--single-padding);
            border-bottom: 1px solid var(--primary-midnight-75);
        }
    }
}

.links-wrapper {
    display: flex;
}

.orders-pagination {
    padding: var(--single-padding);
}
