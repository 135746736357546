.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}


.modal {
    &-content {
        display: flex;
        flex-direction: column;
        min-width: 700px;
        gap: var(--quadruple-padding)
    }

    &-text {
        display: flex;
        gap: var(--single-padding);
        flex-direction: column;
    }

    &-buttons {
        display: flex;
        gap: var(--single-padding);
        align-self: flex-end;
    }
}
