.datalist-container {
    display: flex;
    padding: var(--half-padding);
    margin-bottom: 0;
}

.datalist-container--isAlternatingColor:nth-child(odd) {
    background: var(--highlight-blue);
}

.datalist-key-spacing {
    margin-right: var(--single-padding);
}

.datalist-key-icon {
    display: inline;
    margin-right: 6px;
}
