.logo {
    max-height: 45px;
}

.nav-container {
    display: flex;
    gap: var(--single-padding);
    align-items: center;
    height: fit-content;
}

.nav-button {
    border-color: transparent;
}

.currency-text {
    font-weight: bold;
    padding: 10px 20px 10px 20px;
    border: 1px solid var(--primary-midnight-75);
}