.label {
  font-size: var(--font-size-S);
  color: var(--dark-gray);
}

.label--alt {
  color: var(--white);
}

// create outer circle
.customRadio {
  position: relative;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: var(--half-padding);
  border: 1px solid var(--dark-gray);
  background-color: var(--white);
  border-radius: 50%;

  // create inner circle (starts off transparent)
  &:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}

.container {
  display: flex;
  position: relative;
  min-height: 1.25rem;
  align-content: flex-start;
  cursor: pointer;
  color: var(--white);

  /* label styles when input is disabled */
  &--disabled {
    cursor: not-allowed;
  }

  /* label color when input is focused */
  &:focus-within {
    color: var(--tertiary-sky-blue-25)
  }

  input {
    /* hide the browser input */
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;

    /* when unchecked and focused OR active */
    &:is(:focus, :active) {
      & ~ .customRadio {
        border: 1px solid var(--tertiary-sky-blue-25);
      }
      & ~ .label {
        color: var(--tertiary-sky-blue-25);
      }
    }

    /* When checked */
    &:checked {
      // outer circle
      & ~ .customRadio {
        border: 1px solid var(--tertiary-sky-blue);
        // inner circle
        &:after {
          background-color: var(--tertiary-sky-blue);
        }
      }

      /* and focused or active */
      &:is(:focus, :active) {
        & ~ .customRadio {
          border: 1px solid var(--tertiary-sky-blue-25);
          &:after {
            background-color: var(--tertiary-sky-blue-25);
          }
        }
        & ~ .label {
          color: var(--tertiary-sky-blue-25);
        }
      }
    }

    /* When disabled */
    &:disabled {
      & ~ .customRadio {
        border: 1px solid var(--light-gray);
      }
      & ~ .label {
        color: var(--disabled-text);
      }

      &:checked {
        & ~ .customRadio {
          border: 1px solid var(--light-gray);
          &:after {
            background-color: var(--light-gray);
          }
        }

        & ~ .label {
          color: var(--disabled-text);
        }
      }
    }
  }
}

.input--errored {
  border-color: var(--error-red);
}
