.extra {
    &__container {
        padding: var(--single-padding);
    }

    &__checkbox-group {
        display: flex;
        flex-flow: column nowrap;
        gap: var(--single-padding);
    }

    &__checkbox-item {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;

        &:last-of-type {
            margin-bottom: var(--single-padding);
        }
    }

    &__checkbox-item > label {
        flex-grow: 1;
    }

    &__checkbox-counter {
        cursor: pointer;
    }

    &__messages {
        padding-top: var(--single-padding);
    }
}
