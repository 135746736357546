.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    min-width: fit-content;
}

.header {
    display: flex;
    justify-content: space-between;
}

.main {
    display:flex;
    gap: var(--single-padding);
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
