.container {
    text-align: center;
    border-radius: var(--normal-border-radius);
    border: 1px solid var(--border-gray);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: var(--double-padding);
    width: 450px;
    background-color: var(--white);
    margin: 0 auto;
}

.form {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.input-wrapper {
    flex-grow: 1;
}

.button-wrapper {
    display: flex;
    gap: var(--single-padding);
    justify-content: center;
}
