span.MuiSlider { // Added span to bump specificity to help overwrite slider styles
    // SCSS variables won't visible on the page unlike CSS variables, it's useful for us to easly apply changes to components like this
    $thumb-size: 12px; // thumb is a circle we drag to control slider
    $thickness: 2px;

    &-root {
        color: var(--tertiary-sky-blue);
        width: calc(100% - $thumb-size); // thumbs are outside it's container edges so we need to shrink the width by 2 * (half of thumb)
        height: $thickness;
        margin: 0 0 var(--quarter-padding) calc($thumb-size / 2); // need to move slider by half size of a thumb to the right
    }

    &-rail {
        color: #F2F2F2;
        opacity: 1;
        width: calc(100% + $thumb-size - 2px); // compenstaing for shrinking the slider as we want to keep the rail aligned to the container sides
        height: $thickness;
        margin: 0 0 0 calc(-1 * (($thumb-size - 2px) / 2)); // compensating for moving the slider to the right, rail needs to move to the left to touch its container sides, result is negative margin with the size of half thumb -1
    }

    &-thumb {
        width: $thumb-size;
        height: $thumb-size;
        color: var(--white);
        border: 3px solid var(--tertiary-sky-blue);
    
        &:before {
            display: none;
        }

        &:focus, &:hover, &.Mui-focusVisible, &.Mui-active,  &.MuiSlider-dragging {
            box-shadow: none;
        }
    }
}

.slider__range-values {
    display: flex;
    justify-content: space-between;
}
