.container {
    margin: 0 auto;
    max-width: var(--login-card-width);
    display: flex;
    flex-direction: column;
}

.login-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    border-radius: var(--normal-border-radius);
    border: 1px solid var(--border-gray);
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: var(--double-padding);
    gap: var(--single-padding);
}

.field-set {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--single-padding);
}

@media only screen and (max-width: 720px){
    .field-set {
        flex-direction: column;
    }
}

.inputs-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: var(--login-input-width);
}

.submit-button {
    width: var(--login-input-width);
}

.submit-login-button-wrapper {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    gap: var(--quarter-padding);
}

.password-input {
    width: calc(var(--login-input-width) + var(--single-padding) + 2px);
}

.align-right {
    align-self: flex-end;
}

.align-left {
    align-self: flex-start;
}

.signup-text {
    display: flex;
    gap: var(--quarter-padding);
    align-self: flex-start;
}
