.container {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
    min-width: var(--medium-panel-width);
    flex-grow: 1;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: var(--double-padding);
    gap: var(--single-padding);
}

/*.card-row {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: flex-end;*/
/*    justify-content: space-between;*/
/*    gap: var(--single-padding);*/
/*}*/

.card-inputs {
    display: flex;
    flex-direction: row;
    gap: var(--single-padding);
}
