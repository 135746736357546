.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.price-info-text {
    display: flex;
    gap: var(--half-padding);
    padding: var(--single-padding);
    background-color: var(--white);
    border: 2px solid var(--border-gray);
    border-radius: var(--normal-border-radius);
}
