.link {
    color: var(--tertiary-sky-blue);
    &:hover {
     text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
    &--alt {
        color: var(--white);
    }
    &--no-underline {
        text-decoration: none;
    }
    &--bold {
        font-weight: bold;
    }
}
