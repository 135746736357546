.container {
    display: flex;
    flex-direction: column;
}

.header {
    padding: var(--half-padding);
    border-radius: 12px 12px 0 0;
    background: var(--primary-midnight);

    &--closed {
        border-radius: 12px;
    }
    &--alt {
        color: var(--primary-midnight);
        background: var(--white);
    }
}

.button {
    background-color: inherit;
    padding: 0;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    width: 100%;

    border: 5px solid transparent;
    border-radius: 12px;

    &:focus {
        outline: 2px solid var(--white);
    }
}

.icon {
    & svg {
        fill: var(--white);
        margin: 0 8px;
    }
    &--alt svg {
        fill: var(--primary-midnight);
    }
}

.content {
    background-color: var(--white);
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 250ms ease;
    border-bottom-right-radius: var(--normal-border-radius);
    border-bottom-left-radius: var(--normal-border-radius);

    &--border-bottom {
        border-bottom: 3px solid var(--primary-midnight);
    }
    &--border-top {
        border-top: 3px solid var(--primary-midnight);
    }
}

.content[aria-hidden='false'] {
    grid-template-rows: 1fr;
}

.content > div {
    overflow: hidden;
}

.content-hide > div {
    transform-origin: top;
    transform: scaleY(0);
    visibility: hidden; // needed so focus doesn't go to all the content when a accordion is closed
    transition: visibility 250ms ease, transform 250ms ease; // needed so the animation occurs with content shown, not suddenly blank
}
