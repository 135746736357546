.list {
    display: grid;
    &-header {
        gap: var(--double-padding);
        padding: var(--single-padding);
        display: grid;
        grid-column: 1/4;
        grid-template-columns: 1.5fr 1fr 1fr 1.25fr;
        border-bottom: 2px solid var(--border-gray);
    }
    &-item {
        display: grid;
        grid-column: 1/4;
        grid-template-columns: 1.5fr 1fr 1fr 1.25fr;
        gap: var(--double-padding);
        padding: var(--single-padding);
        border-bottom: 2px solid var(--border-gray);
        &:last-child {
            border-bottom: none;
        }

        &--left {
            display: flex;
            flex-direction: column;
            align-self: center;
            flex: 1 1;
            gap: var(--half-padding);
            word-break: break-word;
        }
        &--middle {
            align-self: center;
        }
        &--right {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            align-items: center;
            gap: var(--single-padding);
            flex: 1 1 auto;
        }
    }
}

.status-wrapper {
    display: flex;
    align-items: center;
    gap: var(--half-padding);
}

.reset-password-modal-content {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    padding: var(--single-padding);
}

.button-group {
    display: flex;
    gap: var(--half-padding);
    margin-top: var(--single-padding);
    margin-bottom: var(--single-padding);
    margin-left: auto;
}

.inline-spinner {
    display: flex;
    align-items: center;
}
