.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding)
}

.wrapper {
    min-width: 540px;
    padding: var(--double-padding);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 350px;
    min-height: 220px;
    gap: var(--single-padding);
}

.button-wrapper {
    display: flex;
    gap: var(--single-padding);
    align-self: flex-end;
}