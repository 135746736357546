.sales-channel {
    &__list {
        background-color: var(--white);
        padding: var(--double-padding);
        border-radius: var(--normal-border-radius);
        display: grid;
        gap: var(--single-padding);
    }

    &__description {
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: 2fr 1fr 0.5fr;
    }

    &__controls {
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: repeat(3, 1fr);
    }
}
