.label {
    font-size: var(--font-size-S);
    color: var(--primary-midnight);

    &--errored {
        color: var(--error-red);
    }
    &--alt {
        color: var(--white);
    }
}

.hide {
    display: none;
}

/* Create a custom checkbox */
.checkbox {
    position: relative;
    flex-shrink: 0;
    height: .9375rem;
    width: .9375rem;
    margin-right: var(--half-padding);
    border: 1px solid var(--blue-tint);
    background-color: var(--white);
    border-radius: 1px;

    /* Create the hidden tick/indicator (only show when checked) */
    &:after {
        content: "";
        position: absolute;
        display: none;
        width: 90%;
        height: 90%;
        // centering
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Customize the whole container (the label element) */
.container {
    display: flex;
    position: relative;
    min-height: 1.25rem;
    align-items: center;
    cursor: pointer;

    &--disabled {
        cursor: not-allowed;
    }

    input {
        /* Hide the browser's default checkbox */
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        /* When unchecked and focus OR active */
        &:is(:focus, :active) {
            & ~ .checkbox {
                border-color: var(--tertiary-sky-blue-25);
            }
            & ~ .label {
                color: var(--tertiary-sky-blue-25);
            }
        }

        /* When checked */
        &:checked {
            & ~ .checkbox {
                background-color: var(--tertiary-sky-blue);
                border-color: var(--tertiary-sky-blue);
                /* Show the tick */
                &:after {
                    mask-image: url('./../../Icon/svgs/check.svg');
                    background-color: var(--white);
                    display: block;
                }
            }

            /* and focused or active */
            &:is(:focus, :active) ~ .checkbox {
                border-color: var(--tertiary-sky-blue-25);
                background-color: var(--tertiary-sky-blue-25);
            }
            &:is(:focus, :active) ~ .label {
                color: var(--tertiary-sky-blue-25);
            }
        }

        /* When disabled */
        &:disabled {
            & ~ .checkbox {
                border-color: var(--light-gray);
            }
            & ~ .label {
                color: var(--disabled-text);
            }

            /* and checked */
            &:checked {
                & ~ .checkbox {
                    border-color: var(--light-gray);
                    background-color: var(--light-gray);
                }
                & ~ .label {
                    color: var(--disabled-text);
                }
            }
        }
    }
}

.input--errored {
    border-color: var(--error-red);
}
