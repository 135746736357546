.footer-container {
    padding: var(--double-padding);
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
}
.link-container {
    display: flex;
    gap: var(--triple-padding);
    & a {
        font-weight: bold;
        text-decoration: none;
        padding: var(--half-padding);
    }
}

.link-container--column {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.logo-and-company-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--single-padding);
    & span {
        max-width: unset;
    }
}
.logo {
    max-height: 45px;
}
