.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;
    min-width: 171px;
    /* set so a $999,9999.00 cruise will fit */
    max-width: 171px;
    /* set so a $999,9999.00 cruise will fit */
    background-color: var(--primary-midnight);
    padding: 22px;
    border-radius: 0 var(--large-border-radius) var(--large-border-radius) 0;
    gap: var(--single-padding);
}

.button-container {
    width: 100%;
}

.select-button {
    width: 100%;
}

.alternative-sailings-button {
    width: 100%;
    margin-top: var(--single-padding);
}

.pricing-items {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
    width: 100%;
}

.pricing-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--accent-orange-sun);
}

.fares {
    font-family: var(--mono-fonts);
}

.center-align {
    text-align: center;
}

.no-bottom-border-radius {
    border-radius: 0 var(--large-border-radius) 0 0;
}
