.wrapper {
    display: flex;
    align-items: center;
    gap: var(--one-and-half-padding);
}

.img-placeholder {
    min-width: 315px;
    min-height: 171px;
    flex-grow: 1;
    border-radius: var(--normal-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BCBEC9FF' stroke-width='3' stroke-dasharray='6%2c 8' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: #f8f8f8;
    cursor: pointer;
}

.img-placeholder-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--half-padding);
}

.label-block {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
}
