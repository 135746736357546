.item {
    &__container {
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: 1fr 2fr;

        .item__error {
            grid-column: 1 / 3;
        }
    }

    &__controls {
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: 2fr 1fr 0.5fr;
        align-items: center;
    }

    &__fields {
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: 1fr 1fr;
        gap: var(--half-padding);
        overflow: hidden;
        max-height: 4000px; // it's there to handle css transitions, it should be a relatively high number to fit all of the possible credentials inputs but it will always take minimum amount of space
        transition: 2.5s ease;

        &[aria-hidden='true'] {
            max-height: 0;
            margin-top: 0;
            transition: .5s ease;
        }

        &-container {
            grid-column: 1 / 4;

            & label > span {
                font-size: var(--font-size-S);
            }

            & div > span {
                font-size: var(--font-size-XXS);
            }
        }

        & > form {
            grid-column: 1 / 3;
            display: grid;
            grid-template-columns: 1fr 1fr 2fr;
            gap: var(--single-padding);
            align-items: center;
            padding-bottom: var(--single-padding);
            border-bottom: 1px solid var(--border-gray);

            & .item-save-button {
                display: grid;
                grid-column: 1/4;
                grid-template-columns: 2fr 1fr .5fr;

                & > button {
                    grid-column: 3;
                    margin: var(--border-S);
                }
            }
        }
    }

    &__field {
        margin: var(--single-padding) 0;
        max-width: 550px;
    }

    &__message {
        grid-column: 1 / 3;
        
        & > div {
            margin: var(--single-padding) 0;
        }
    }
}
