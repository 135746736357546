.container {
    min-width: fit-content;
    height: 60vh;
}

.header {
    text-align: center;
    padding: var(--single-padding);
}

.form {
    background: var(--primary-midnight);
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    padding: var(--single-padding) calc(var(--single-padding) + var(--half-padding));
    border-radius: var(--large-border-radius);
}

.not-bold {
    font-weight: normal;
    margin-right: var(--single-padding);
}