.container {
    display: flex;
    flex-direction: column;
}

.header {
    background-color: inherit;
    padding: 0;
    padding-bottom: 4px;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 12px 12px 0 0;
    &-toggle {
        display: flex;
    }
    &-icon {
        margin: 0 8px;
        right: 0;
    }
}

.header > button {
    border: 5px solid transparent;
    border-radius: 12px;
    &:focus {
        outline: 2px solid var(--primary-midnight);
    }
}

.content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 250ms ease;
    border-bottom-right-radius: var(--normal-border-radius);
    border-bottom-left-radius: var(--normal-border-radius);
}

.content[aria-hidden='false'] {
    grid-template-rows: 1fr;
}

.content > div {
    overflow: hidden;
}

.content-hide > div {
    transform-origin: top;
    transform: scaleY(0);
    visibility: hidden; // needed so focus doesn't go to all the content when a accordion is closed
    transition: visibility 250ms ease, transform 250ms ease; // needed so the animation occurs with content shown, not suddenly blank
}
