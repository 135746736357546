.container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: var(--double-padding);
}

.api-users-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--half-padding);
}

.api-users-user-pass-and-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--half-padding);
}

.horizontal-boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.item {
    display: flex;
    flex-direction: column;
    padding: var(--double-padding);
    gap: var(--double-padding);
    background-color: var(--white);

    &-box {
        display: flex;
        flex-direction: row;

        &--vertical {
            flex-direction: column;
            flex: 1;
        }

        &--half-gap {
            gap: var(--half-padding);
        }

        &--no-gap {
            gap: 0;
        }
    }

    &-text {
        padding-right: var(--quarter-padding);
    }

    &-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--tertiary-sky-blue);
        width: 50px;
        height: 50px;
        border-radius: var(--large-border-radius);
    }

    &-credentials-error-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--half-padding) 0;
    }

    &-inline-link {
        padding: 0 var(--quarter-padding);
    }
}

.text-box {
    padding: var(--half-padding) var(--single-padding);
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: var(--disabled-background);

    &-container {
        display: flex;
    }

    &-button--copied svg {
        transform: translateX(2px);
    }

    &-item {
        display: flex;
        flex-direction: column;
        gap: var(--half-padding);

        & button {
            padding: var(--quarter-padding);
            background-color: var(--disabled-background);
            border-left: 1px solid var(--gray);
            border-radius: 0 var(--normal-border-radius) var(--normal-border-radius) 0;

            &:hover,
            &:active,
            &:focus {
                border: var(--border-L) solid transparent;
                outline: none;
                border-left: 1px solid var(--gray);
                background-color: var(--disabled-background);

                & svg {
                    fill: var(--tertiary-sky-blue);
                }
            }

            & svg {
                fill: var(--primary-midnight);
            }
        }

        &:nth-of-type(1) .text-box {
            border: 1px solid var(--gray);
            border-top-left-radius: var(--normal-border-radius);
            border-bottom-left-radius: var(--normal-border-radius);
        }

        &:nth-of-type(2) .text-box {
            border-top-right-radius: var(--normal-border-radius);
            border-bottom-right-radius: var(--normal-border-radius);
        }
    }

    &-password-field {
        display: flex;
        flex-direction: row;
        border-top: 1px solid var(--gray);
        border-bottom: 1px solid var(--gray);
        border-right: 1px solid var(--gray);
        border-top-right-radius: var(--normal-border-radius);
        border-bottom-right-radius: var(--normal-border-radius);
    }
}
