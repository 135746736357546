.container {
    margin-bottom: var(--triple-padding);
    & > div > div {
        border: none;
    }
}

.table-container {
    max-height: 370px;
}

.table {
    border-spacing: 0;
    thead {
        background-color: var(--primary-midnight);
        padding: var(--single-padding);
        tr {
            margin: 0 var(--half-padding) 0 var(--one-and-half-padding);
            margin-right: 30px; // to offset for scrollbar, otherwise tbody columns wouldnt match thead columns
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
        }
    }
    th, td {
        padding: var(--single-padding) 0;
    }
    td {
        display: flex;
        align-items: center;
        padding-right: var(--single-padding);
    }
    tbody {
        position: relative;
        display: block;
        max-height: 320px;
        overflow-y: scroll;
    }
    &-body-row {
        border-bottom: 1px solid var(--border-gray);
        padding: 0 var(--half-padding) 0 var(--one-and-half-padding);
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
        &:hover {
            background-color: var(--highlight-blue);
        }
    }
    &-button {
        justify-content: flex-end;
    }
}

.rounded-top-border-radius {
    border-radius:  var(--large-border-radius) var(--large-border-radius) 0 0;
    display: block;
}