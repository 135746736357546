.container {
    flex: 1 1;
    flex-grow: 1;
    max-width: var(--page-width-desktop);
    min-width: var(--page-width-mobile);
    width: 100%;
    margin: 0 auto;
    padding: var(--double-padding);
}

.content-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--half-padding) + var(--quarter-padding));
    height: 100%;
}

.content-row>span {
    text-align: center;
}