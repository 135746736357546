.menu {
    &__button {
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: var(--half-padding);
        padding: 10px var(--single-padding);
        border-radius: var(--normal-border-radius);

        &:disabled {
            cursor: not-allowed;
        }
        &:focus,
        &:hover,
        &:active {
            background-color: var(--border-gray);
        }
        &--onDarkBackground:focus,
        &--onDarkBackground:hover,
        &--onDarkBackground:active {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    &__button-text--disabled {
        color: var(--disabled-text);
    }

    &__list-container {
        padding: var(--single-padding) 0;
        border: 1px solid var(--border-gray);
        border-radius: 0px 0px var(--large-border-radius) var(--large-border-radius);
        background: var(--white);
        box-shadow: var(--box-shadow);
        &--invert {
            background: var(--primary-midnight);
            border-top: var(--primary-midnight);
            border-right: 1px solid var(--primary-midnight-25);
            border-bottom: 1px solid var(--primary-midnight-25);
            border-left: 1px solid var(--primary-midnight-25);
        }
        &--top-margin-double {
            margin: calc(var(--double-padding) - 1px) 0 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: var(--single-padding);
    }

    &__list-item {
        display: flex;
        justify-content: flex-start;
        padding: calc(var(--half-padding) - 3px) var(--single-padding);
        gap: var(--half-padding);
        min-width: 150px;
        box-sizing: border-box;
        border: 3px solid transparent;
        &:hover {
            background: var(--primary-midnight-75);
        }
        &:focus {
            background: var(--primary-midnight-75);
            border: 3px solid var(--primary-midnight);
        }
        &--invert {
            padding: var(--half-padding) var(--double-padding);
            &:hover {
                background: var(--primary-midnight-25);
            }
            &:focus {
                background: var(--primary-midnight-25);
                border: 3px solid var(--white);
            }
        }
        &--custom-element {
            padding: var(--half-padding) var(--double-padding);
            &:hover {
                background: transparent;
            }
        }
    }
}
