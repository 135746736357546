.color-gray {
    color: var(--gray)
}

.color-divider-gray {
    color: var(--divider-gray)
}

.color-currency-gray {
    color: var(--primary-midnight-75)
}

.color-dark-gray {
    color: var(--dark-gray)
}

.color-red {
    color: var(--error-red)
}

.color-tertiary-blue {
    color: var(--tertiary-sky-blue)
}

.color-primary-blue {
    color: var(--primary-midnight)
}

.color-primary-blue-40 {
    color: var(--primary-midnight-40)
}

.color-white {
    color: var(--white)
}

.color-disabled {
    color: var(--disabled-text)
}

.color-orange {
    color: var(--accent-orange-sun);
}

.color-green {
    color: var(--success-green);
}

.size-XS {
    font-size: var(--font-size-XS);
}

.size-S {
    font-size: var(--font-size-S);
}

.size-M {
    font-size: var(--font-size-M);
}

.size-L {
    font-size: var(--font-size-L);
}

.size-XL {
    font-size: var(--font-size-XL);
}

.size-XXL {
    font-size: var(--font-size-XXL);
}

.size-XXXL {
    font-size: var(--font-size-XXXL);
}

.weight-normal {
    font-weight: normal;
}

.weight-bold {
    font-weight: bold;
}

.font-normal {
    font-family: var(--standard-fonts);
}

.font-mono {
    font-family: var(--mono-fonts);
}

.font-heading {
    font-family: var(--heading-fonts);
}
