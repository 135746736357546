.full-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
}

.content {
    display: flex;
    flex: 1;
}
