.table {
    border-collapse: collapse;
    border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius) var(--normal-border-radius);
    overflow: hidden;
}

.table th {
    padding: var(--single-padding);
}

.table td {
    padding: var(--single-padding);
}

.table-body-row {
    border-bottom: 1.5px solid var(--border-gray);
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
    &:hover {
        background-color: var(--highlight-blue);
    }
}

.selected {
    background-color: var(--highlight-blue);
}

.table thead tr {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1.5px solid var(--border-gray);
}
