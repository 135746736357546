.spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem auto 8rem auto;
    gap: var(--single-padding);
}

.circle {
    position: relative;
    width: 50px;
    height: 50px;
}

.path {
    stroke: url(#gradient);
    stroke-width: 6;
    stroke-dasharray: 80;
    stroke-dashoffset: 60;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    transform-origin: center center;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 80;
        transform: rotate(0deg);
    }
    100% {
        stroke-dashoffset: 80;
        transform: rotate(360deg);
    }
}
