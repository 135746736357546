.footer {
    background-color: var(--primary-midnight);
    min-height: 120px;
}

.content {
    align-self: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: var(--page-width-mobile);
    padding: var(--single-padding);
    max-width: var(--page-width-desktop);
    margin: 0 auto;
}