.container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--half-padding);
    padding: var(--single-padding);
}

.columns {
    display: flex;
    flex-grow: 1;
}