.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    text-align: left;
}

.temp-password-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--single-padding);
}

.password-input {
    width: calc(var(--login-input-width) + var(--single-padding) + 2px);
}

.field-set {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--single-padding);
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    width: var(--login-input-width);
}

.submit-button {
    width: var(--login-input-width);
}