.container {
    min-width: 750px;
}

.controller-container {
    padding: var(--half-padding) 0;
    list-style-type: none;
    display: flex;
    font-weight: bold;
    gap: var(--quarter-padding);
    align-items: center;
    color: var(--primary-midnight);
    justify-content: center;
}

.buttons {
    min-height: 42px;
    min-width: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
        background-color: var(--highlight-blue);
        cursor: pointer;
    }

    &:active {
        // TODO: see if these should match our button component
        background-color: var(--tertiary-sky-blue-25);
        color: var(--off-white);
    }

    & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
    }
}


.active-item {
    background-color: var(--tertiary-sky-blue);
    color: var(--off-white);

    &:hover {
        background-color: var(--tertiary-sky-blue) !important;
        cursor: not-allowed !important;
    }
}
