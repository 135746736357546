.container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.label {
    margin-top: 4px;
    margin-bottom: 12px;
    display: block;
}

.asterisk {
    font-weight: bold;
    color: red;
}
