.description-list {
    display: flex;
    flex-direction: column;
}

.item-key {
    display: inline-block;
    margin-inline-start: 0;
}

.item-value {
    display: inline-block;
    overflow-wrap: anywhere;
    margin-inline-start: 0;
}
