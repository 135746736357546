.list {
    display: flex;
    align-items: center;
    margin: var(--single-padding);
}

.list-item {
    display: flex;
}

.link {
    color: var(--primary-midnight-40);
}

.arrow-divider {
    margin: 0 var(--double-padding);
    color: var(--primary-midnight-40);
}
