.deck-select {
    width: fit-content;
}

.radio-group {
    flex-direction: column;
}

.cabin-form {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.cabin-form-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: var(--double-padding);
}

.cabin-form-column {
    flex: 1;
}

.button-container {
    margin-top: var(--single-padding);
    display: flex;
    justify-content: flex-end;
}

.extras-heading {
    display: flex;
    flex: 1;
    background-color: var(--primary-midnight);
    padding: var(--single-padding);
    border-top-left-radius: var(--large-border-radius);
    border-top-right-radius: var(--large-border-radius);
}

.content {
    background-color: var(--white);
    border-bottom-right-radius: var(--normal-border-radius);
    border-bottom-left-radius: var(--normal-border-radius);
}

.extras-inputs {
    display: flex;
    flex-direction: column;
    padding-top: var(--single-padding);
    padding-left: var(--single-padding);
    padding-bottom: var(--single-padding);
    select {
        width: 300px;
    }
}

.fields-required-message {
    padding: var(--single-padding) 0 0 var(--single-padding);
}