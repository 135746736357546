.modalContent {
    min-height: 200px;
    min-width: 540px;
}

.modalForm {
    display: flex;
    flex-direction: column;
    gap: var(--one-and-half-padding);
}

.container {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.actions {
    display: flex;
    gap: var(--single-padding);
    justify-content: flex-end;
}
