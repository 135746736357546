@font-face {
    font-family: 'HouschkaRounded';
    src: url("./HouschkaRounded/HouschkaRounded-Medium.woff") format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'HouschkaRounded';
    src: url("./HouschkaRounded/HouschkaRounded-Bold.woff") format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'QuickSand';
    src: url("./QuickSand/Quicksand-Regular.woff2") format('woff2');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'QuickSand';
    src: url("./QuickSand/Quicksand-Bold.woff2") format('woff2');
    font-style: normal;
    font-weight: 700;
}
