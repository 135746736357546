.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    justify-content: space-between;
    min-width: fit-content;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: var(--double-padding);
}

.cabin-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.modal-headings {
    font-size: 1rem;
    font-weight: bold;
}

.modal-smaller-headings {
    font-size: 0.8rem;
    font-weight: bold;
}

.modal-text {
    font-size: 0.8rem;
    font-weight: normal;
    color: var(--dark-gray);
}

.passenger-info-button {
    margin: var(--single-padding);
}
