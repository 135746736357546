.container {
    display: flex;
    align-items: center;
}

.icon-size-XS {
    height: var(--font-size-XS);
}

.icon-size-S {
    height: var(--font-size-S);
}

.icon-size-M {
    height: var(--font-size-M);
}

.icon-size-L {
    height: var(--font-size-L);
}

.icon-size-XL {
    height: var(--font-size-XL);
}

.icon-color-white {
    fill: var(--white);
}

.icon-color-tertiary-blue {
    fill: var(--tertiary-sky-blue);
}

.icon-color-tertiary-blue-25 {
    fill: var(--tertiary-sky-blue-25);
}

.icon-color-primary-midnight-40 {
    fill: var(--primary-midnight-40);
}

.icon-color-gray {
    fill: var(--gray);
}

.icon-color-dark-gray {
    fill: var(--dark-gray);
}

.icon-color-pink {
    fill: var(--secondary-hot-pink);
}

.icon-color-red {
    fill: var(--error-red);
}

.icon-color-orange {
    fill: var(--accent-orange-sun);
}

.icon-color-green {
    fill: var(--success-green);
}

.icon-color-primary-midnight {
    fill: var(--primary-midnight);
}
