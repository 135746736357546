.deck-legend-image-container {
    display: flex;
    max-width: 320px;
    width: 100%;
}

.deck-image-container {
    height: 100%;
    min-width: 320px;
    flex-shrink: 1;
    border-radius: var(--single-padding);
    border: 1.5px solid var(--border-gray);
    background: var(--white);
    padding: var(--single-padding);
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder-container {
    position: relative;
    display: inline-block;
}

.placeholder-image {
    width: 100%;
    border-radius: var(--single-padding);
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    width: 298px;
    text-align: center;
}
