.category-indicator {
    border-radius: 12px;
    margin-left: var(--single-padding);
    padding: var(--quarter-padding) var(--single-padding);
    text-align: center;
    min-width: 7rem;
    align-self: center;
}

.category-indicator-port {
    background-color: #1A7DA8;
}

.category-indicator-supplier {
    background-color: #757575;
}

.category-indicator-ship {
    background-color: #1A20A8;
}

.category-indicator-country {
    background-color: #238538;
}

.category-indicator-destination {
    background-color: var(--secondary-hot-pink-25);
}

.category-indicator-region {
    background-color: #CF0C0C;
}
