.container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    margin: 0 auto;
    max-width: var(--login-card-width);
    text-align: center;
    border-radius: var(--normal-border-radius);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: var(--double-padding);
    background-color: var(--white);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: var(--single-padding);
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    width: var(--login-input-width);
}

@media only screen and (max-width: 720px){
    .input-wrapper {
        flex-grow: 1;
    }
}

.submit-login-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}

.submit-login-container {
    display: flex;
    width: var(--login-input-width);
    flex-direction: column;
    gap: var(--single-padding);
}


.login-text {
    display: flex;
    gap: var(--quarter-padding);
}

.submit-login-button-wrapper {
    display: flex;
    justify-content: center;
    padding: var(--single-padding);
    gap: var(--single-padding);
}

.signup-error-message {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
