.content {
    display: flex;
    flex-direction: column;
    padding: var(--single-padding) var(--one-and-half-padding);

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: var(--single-padding);

        &__list {
            padding-left: 0;
        }
    }
}
