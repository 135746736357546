.wrapper {
    max-width: 540px;
    padding: var(--double-padding);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 350px;
    min-height: 220px;
    gap: var(--single-padding);
}

.button-wrapper {
    display: flex;
    gap: var(--single-padding);
    align-self: flex-end;
}

.spinner {
    padding: var(--half-padding);
    border: 1px solid var(--dark-gray);
    border-radius: var(--normal-border-radius);
}
