.filter {
    &__container {
        border: 1.5px solid var(--border-gray);
        border-radius: 12px 12px 4px 4px;
        min-width: 350px;
        height: fit-content;
        background-color: var(--white);
    }
    
    &__heading-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: var(--single-padding);
        padding: var(--single-padding);
        background-color: var(--primary-midnight);
        border-radius: 12px 12px 0 0;

        &-top-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        padding: var(--double-padding);
        gap: var(--single-padding);
    }

    &__price-range-values {
        display: flex;
        justify-content: space-between;
    }

    &__checkbox-group {
        display: flex;
        flex-flow: column nowrap;
        gap: var(--single-padding);
        &--ships {
            max-height: 400px;
            overflow-y: auto;
        }
    }

    &__checkbox-item {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        padding-right: calc(var(--single-padding) + var(--quarter-padding));
    }

    &__checkbox-item > label{
        flex-grow: 1;
    }

    &__checkbox-counter {
        cursor: pointer;
    }
}
