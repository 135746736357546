.breakdown-table {
    border-collapse: collapse;
    margin-bottom: var(--single-padding);
}

.breakdown-table th {
    padding: var(--single-padding) var(--half-padding);
}

.breakdown-table td {
    padding: var(--single-padding) var(--half-padding);
}

.breakdown-table tr {
    border-bottom: 0.5px solid var(--primary-midnight);
}

.breakdown-table tbody tr:last-child {
    border-bottom: 0.5px solid transparent;
}

/* style head row data (column headers) */
.breakdown-table thead tr th {
    text-align: center;
    white-space: nowrap;
}

/* style body row heading r*/
.breakdown-table tbody tr th {
    text-align: left;
    width: 100%;
}

/* style body data cells */
.breakdown-table tbody tr td {
    text-align: right;
    font-family: Courier, monospace;
}

/* Style last row of data cells */
.breakdown-table tbody tr:last-child td {
    font-weight: bold !important;
}
/* Style from last row header cell */
.breakdown-table tbody tr:last-child th {
    font-weight: bold !important;
}

/* bold last row */
.breakdown-table tbody tr:last-child th span {
    font-weight: bold !important; /* need to override <Text> styles with important*/
}
.breakdown-table tbody tr:last-child td span {
    font-weight: bold !important;
}

.breakdown-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.breakdown-header-column {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
}

.breakdown-header-included {
    display: flex;
    flex-wrap: wrap;
    gap: var(--single-padding);
}

.breakdown-header-included-item {
    display: flex;
    flex-direction: row;
    gap: var(--quarter-padding);
}

.info-icon {
    margin-right: var(--single-padding);
}

.grand-total {
    padding-top: var(--half-padding);
    display: flex;
    justify-content: space-between;
}

.accordion-text {
    padding: var(--single-padding);
    display: flex;
    justify-content: space-between;
}
