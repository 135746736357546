.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inputs-group--horizontal {
    display: flex;
    justify-content: space-between;
    gap: var(--single-padding);
}

.country-code {
    max-width: 7rem;
}

.phone-number {
    flex: 1;
}
