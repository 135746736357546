.table-wrapper {
    width: calc(var(--page-width-desktop)) - var(--quadruple-padding); /*his size is the desktop size, minus single padding 4 times due to being in two containers from page content*/
    margin: var(--single-padding);
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table tr {
    border-bottom: 1px solid var(--primary-midnight-75);
}

.th-td {
    text-align: center;
    /* to align with (i) button */
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.cabin-grade-name {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    width: 50px;
}

.cabin-grade-col {
    padding-top: 12px;
    padding-bottom: 12px;
    white-space: break-spaces;
    text-align: left;
    width: 250px;
}

.data-cell {
    display: flex;
    white-space: break-spaces;
    align-items: center;
}

.empty-cell {
    padding-left: 12px;
    display: flex;
}

.code-color-block {
    flex-basis: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1rem;
}

.price-link {
    text-decoration: underline;
}

.military-residential {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}
