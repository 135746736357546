.passenger-summary-section {
    &__container {
        padding: var(--single-padding);
    }

    &__preferences {
        display: flex;
        flex-direction: column;
        gap: var(--single-padding);
        margin-top: var(--half-padding);
    }

    &__item-residential {
        display: flex;
        flex-direction: column;
        gap: var(--half-padding);
    }

    &__item-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__item-list {
        padding-left: 0;
    }

    &__item-preference {
        display: flex;
        gap: var(--half-padding);
    }
}
