.container {
    display: flex;
}

.show-password-button {
    background: transparent; /* remove button default */
    border: none; /* remove button default */
    padding: 0; /* remove button default */
    position: relative;
    right: 2.5rem;
    cursor: pointer;
}
