.list {
  display: grid;
  &-header {
    gap: var(--double-padding);
    padding: var(--single-padding);
    display: grid;
    grid-template-columns: 1.25fr 1.75fr;
    border-bottom: 2px solid var(--border-gray);
  }
  &-item {
    display: grid;
    grid-template-columns: 1.25fr 1.75fr;
    gap: var(--single-padding);
    padding: var(--single-padding);
    border-bottom: 2px solid var(--border-gray);
    &:last-child {
      border-bottom: none;
    }

    &--left {
      display: flex;
      align-self: center;
      flex-direction: column;
      flex: 1;
      gap: var(--half-padding);
    }
    &--right {
      display: flex;
      flex-direction: row;
      gap: var(--half-padding);
      justify-content: space-between;
      align-items: center;
      &--sc-title {
        padding-left: var(--single-padding);
      }
      &--actions-button {
        margin-left: auto; /* Pushes the actions button to the right */
      }
    }
  }
}
