// Block of styles that overrides original MUI styles
// "important" is required here
.stepper-label {
    margin-top: 5px !important;
    font-family: var(--standard-fonts) !important;
    font-size: var(--font-size-S) !important;
    font-weight: 700 !important;
    color: var(--secondary-hot-pink-alt) !important;
}
.stepper-label-active {
    color: var(--secondary-hot-pink-alt) !important;
}

.stepper-label-completed {
    color: var(--secondary-hot-pink-alt) !important;
    cursor: pointer !important;
}

.connector-alternativeLabel {
    top: 22px !important;
    background-color: var(--secondary-hot-pink) !important;
}

.connector-line {
    height: 3px !important;
    border: 0 !important;
    border-radius: 1px !important;
}

.connector-line-active {
    background-color: var(--secondary-hot-pink) !important;
}

.connector-line-completed {
    background-color: var(--secondary-hot-pink) !important;
}

.iconWrapper {
    background-color: var(--white);
    border: 3px solid var(--secondary-hot-pink) !important;
    color: var(--secondary-hot-pink);
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconWrapper-active {
    background-color: var(--secondary-hot-pink) !important;
    cursor: inherit !important;
}

.iconWrapper-completed {
    background-color: var(--white);
    cursor: pointer;
}
