.order-confirmation__heading_container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
}

.order-confirmation__container {
    display: flex;
    flex-direction: column;
    gap: var(--single-padding);
    flex-grow: 1;
    min-width: 25rem;
}

.booking-message {
    display: flex;
    gap: var(--half-padding);
}

.booking-message-outer {
    display: flex;
    align-items: center;
}
