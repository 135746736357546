.container {
        background-color: var(--white);
        border: 1.5px solid var(--border-gray);
        border-radius: var(--large-border-radius) var(--large-border-radius) var(--normal-border-radius) var(--normal-border-radius);
        width: fit-content;
        min-width: 300px;
        height: fit-content;
        padding-bottom: var(--double-padding);
}

.heading {
        padding: var(--single-padding);
        background-color: var(--primary-midnight);
        border-radius: var(--large-border-radius) var(--large-border-radius) 0px 0px;
}

.content {
        padding: var(--single-padding) var(--double-padding);
}

.list-item {
        display: flex;
        white-space: nowrap;
        margin: var(--single-padding) 0 var(--half-padding);
        gap: var(--half-padding);
}

.list {
        display: flex;
        flex-direction: column;
        gap: var(--half-padding);
}

.link {
        padding-left: var(--half-padding);
}

@media only screen and (max-width: 1023px) {
        .container {
                min-width: auto;
        }

        .link {
                padding-left: 0;
                white-space: nowrap;
        }

        .content {
                padding: var(--single-padding);
        }
}